import { Switch } from "antd";
import React, { FC } from "react";
import "./Pricing.scss";

const PricingBasic: FC = () => {
  return (
    <div className='pricing-card pricing-basic'>
      <div className='plan-title'>BASIC</div>
      <div className='plan-price'>$0</div>
      <div className='plan-tag'>Free forever</div>
      <div className='plan-desc'>For those looking to try out the platform to automate data capture from ID Cards, Invoices, Receipt</div>
      <ul className='plan-benefits'>
        <li>Limited Pages (50)</li>
        <li>Limited Extraction Fields</li>
        <li>Englisth & Arabic Text</li>
      </ul>
      <div className='support-title'>Support</div>
      <ul className='plan-benefits'>
        <li>Email</li>
        <li>Monday - Friday</li>
      </ul>
      <div className='getstarted-btn'>
        <a href='https://kapp.alazka.ai/account/sigin-in'>
          <button>Get Started</button>
        </a>
      </div>
    </div>
  );
};
const PricingPro: FC = () => {
  return (
    <div className='pricing-card pricing-pro'>
      <div className='plan-title'>PROFESSIONAL</div>
      <div className='plan-price'>
        $0.06<sup>/page</sup>
      </div>
      <div className='plan-desc'>
        For business teams seeking to add advanced tools and improve workflows, capture data points from documents and train on their data
        models
      </div>
      <div className='price-tag'>Everything in Basic, plus:</div>
      <ul className='plan-benefits'>
        <li>Capture line items</li>
        <li>Extract up to 25 fields</li>
        <li>Access Pre-trained models</li>
        <li>Annotation Service</li>
        <li>Confidence Scores</li>
        <li>Add collaborators (3 users)</li>
        <li>Usage Reports</li>
        <li>API Integration</li>
        <li>Self-Learning capability</li>
        <li>$299/month includes up to 5000 pages processing. Overage charges are billed at $0.06/page</li>
      </ul>
      <div className='support-title'>Support</div>
      <ul className='plan-benefits'>
        <li>Call & Email</li>
        <li>Monday - Sunday</li>
      </ul>
      <div className='getstarted-btn'>
        <a href='mailto:business@alazka.ai'>
          <button>Starts at $299/Model/Month</button>
        </a>
      </div>
    </div>
  );
};
const PricingEnterprice: FC = () => {
  return (
    <div className='pricing-card pricing-enterprice'>
      <div className='plan-title'>ENTERPRISE</div>
      <div className='plan-desc'>
        Suitable for organizations that deal with multiple document types, implement custom workflows, train on their data
      </div>
      <div className='price-tag'>Everything in Professional, plus:</div>
      <ul className='plan-benefits'>
        <li>Custom-made client onboarding</li>
        <li>Flexible Licensing Model</li>
        <li>Review Screen</li>
        <li>Custom Integrations</li>
        <li>Tailored API Responses</li>
        <li>Unlimited collaborators</li>
        <li>Customized Workflows</li>
        <li>Document parsing</li>
        <li>Auto Classification</li>
        <li>Dedicated Account Manager</li>
        <li>SLA’s</li>
        <li>One-to-One training</li>
      </ul>
      <div className='support-title'>Support</div>
      <ul className='plan-benefits'>
        <li>Prioritized Call & Email</li>
        <li>SAL based</li>
      </ul>

      <div className='getstarted-btn'>
        <a href='mailto:business@alazka.ai'>
          <button>Contact Sales</button>
        </a>
      </div>
    </div>
  );
};

const Pricing: FC = () => {
  return (
    <section className='pricing-wrapper' id='pricing'>
      <div className='section-title'>Pricing</div>
      <div className='switch-price-type'>
        <span className='document-price'>Document</span>{" "}
        <span className='price-switch-btn'>
          <Switch />
        </span>
        <span className='process-price'>Process</span>
      </div>
      <div className='pricing-cards-container'>
        <PricingBasic />
        <PricingPro />
        <PricingEnterprice />
      </div>
    </section>
  );
};

export default Pricing;
