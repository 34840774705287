import React, { FC } from "react";
import "./HeroBanner.scss";
import config from "../config/config";

const HeroBanner: FC = () => {
  return (
    <section className='hero-banner' id='alazka'>
      <h2 className='banner-title'>
        A no-code, human-centric knowledge process <span className='banner-highlited-text'>automation</span> platform
      </h2>
      <p className='banner-description'>
        KPA provides human-centric intelligence to automate complex and data-driven processes, helping SMEs and business users to achieve
        truly digitally transformed business process automation.
      </p>
      <a href={config.hubspot}>
        <button className='request-demo-btn'>Request a Demo</button>
      </a>
    </section>
  );
};

export default HeroBanner;
