import React, { FC } from "react";
import "./WorkFlow.scss";
import MSPartnerLogo from "../../assets/logo/MS_Startups_Celebration_Badge_Light.png";
import { Carousel } from "antd";

// icons
import Flow1 from "../../assets/icons/workflow/1.svg";
import Flow2 from "../../assets/icons/workflow/2.svg";
import Flow3 from "../../assets/icons/workflow/3.svg";
import Flow4 from "../../assets/icons/workflow/4.svg";
import Flow5 from "../../assets/icons/workflow/5.svg";

import Arrow1 from "../../assets/icons/workflow/arrow-1.svg";
import Arrow2 from "../../assets/icons/workflow/arrow-2.svg";
import Arrow3 from "../../assets/icons/workflow/arrow-3.svg";
import Arrow4 from "../../assets/icons/workflow/arrow-4.svg";

const WorkFlowCard: FC<{ icon: any; title: string; arrowIcon?: any }> = ({ icon, title, arrowIcon }) => {
  return (
    <div className='work-flow-card'>
      {arrowIcon && (
        <div className='arrow-icon'>
          <img src={arrowIcon} alt='Arrow Icon' />
        </div>
      )}
      <div className='card-content'>
        <div className='icon'>
          <img src={icon} alt={title} />
        </div>
        <div className='title'>{title}</div>
      </div>
    </div>
  );
};

const WorkFlow: FC = () => {
  return (
    <section className='work-flow'>
      <Carousel autoplay={false} dots={false}>
        <div className='work-flow-cards'>
          <WorkFlowCard arrowIcon={Arrow1} icon={Flow1} title='Define Process Workflow' />
          <WorkFlowCard arrowIcon={Arrow2} icon={Flow2} title='Connect data source' />
          <WorkFlowCard arrowIcon={Arrow3} icon={Flow3} title='Information retieval' />
          <WorkFlowCard arrowIcon={Arrow4} icon={Flow4} title='Run Automation' />
          <WorkFlowCard icon={Flow5} title='Process Insights' />
        </div>
        <div className='ms-partner-logo'>
          <div className='title'>We are happy to announce that Alazka is now part of Microsoft for Startups Founders Hub!</div>
          <img src={MSPartnerLogo} alt='Microsoft Partner' />
        </div>
      </Carousel>
    </section>
  );
};

export default WorkFlow;
