import React, { FC } from "react";
import "./Integrations.scss";
import AppIntegrationIcons from "../../assets/imgs/app-integration-icons-2.png";
import Lottie from "react-lottie";
import AnimationData from "../../assets/animation/14153-integrations.json";

import Aws from "../../assets/logo/integrations/aws.png";
import GoogleDrive from "../../assets/logo/integrations/google-drive.png";
import MicrosoftAzure from "../../assets/logo/integrations/Microsoft_Azure-Logoe.svg";
import MsDynamincs from "../../assets/logo/integrations/ms-dynamics.png";
import OneDrive from "../../assets/logo/integrations/onedrive-logo.png";
import OracleLogo from "../../assets/logo/integrations/oracle-logo.png";
import Quickbooks from "../../assets/logo/integrations/quickbooks.png";
import Sap from "../../assets/logo/integrations/sap-logo.png";
import Zoho from "../../assets/logo/integrations/zoho.png";

const Integrations: FC = () => {
  const animationOpt = {
    loop: true,
    autoplay: true,
    animationData: AnimationData,
    rendererSetting: {
      preserveAspectRatio: "xMidYMid slice 2",
    },
  };
  return (
    <section className='integrations' id='integrations'>
      <h2 className='section-title'>Integrations</h2>
      <p className='section-description'>
        We have one of the kind vertically integrated comprehensive range of enterprise apps and databases run by businesses
      </p>
      <div className='app-integrations'>
        <img src={Aws} alt='' style={{ width: "8%" }} />
        <img src={MicrosoftAzure} alt='' />
        <img src={MsDynamincs} alt='' />
        <img src={OneDrive} alt='' />
        <img src={OracleLogo} alt='' />
        <img src={Quickbooks} alt='' />
        <img src={Sap} alt='' style={{ width: "10%" }} />
        <img src={Zoho} alt='' />
      </div>
      <div className='right-animation'>
        <Lottie options={animationOpt} height={450} width={300} />
      </div>
    </section>
  );
};

export default Integrations;
