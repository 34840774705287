import ReactDOM from "react-dom/client";
import "./index.css";
import GetStartedPage from "./Pages/GetStartedPage/GetStartedPage";
import LandingPage from "./Pages/LandingPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UpgradePage from "./Pages/UpgradePage/UpgragePage";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<LandingPage />} />
      <Route path='/get-started' element={<GetStartedPage />} />
      <Route path='/upgrade-plan' element={<UpgradePage />} />
    </Routes>
  </BrowserRouter>
);
