import React, { FC } from "react";
import Header from "../components/Header/Header";
import HeroBanner from "../components/HeroBanner/HeroBanner";
import Highlights from "../components/Highlights/Highlights";
import Integrations from "../components/Integrations/Integrations";
import Pricing from "../components/Pricing/Pricing";
import UseCases from "../components/UseCases/UseCases";
import ValueWeDeliver from "../components/ValueWeDeliver/ValueWeDeliver";
import WorkFlow from "../components/WorkFlow/WorkFlow";
import Footer from "../components/Footer/Footer";
import "./LandingPage.scss";

const LandingPage: FC = () => {
  return (
    <section className='landing-page-wrapper'>
      <Header />
      <HeroBanner />
      <WorkFlow />
      <ValueWeDeliver />
      <UseCases />
      <Integrations />
      <Pricing />
      <Highlights />
      <Footer />
    </section>
  );
};

export default LandingPage;
