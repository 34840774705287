import React, { FC } from "react";
import "./ValueWeDeliver.scss";

const ValueWeDeliver: FC = () => {
  return (
    <section className='value-we-deliver' id='value-we-devilver'>
      <h2 className='section-title'>
        Value we <span className='text-bg'>deliver</span>
      </h2>
      <div className='all-cards'>
        <div className='center-card'>
          <h3 className='card-title'>
            Unlock the potential of your unstructured data by achieving fully automated business processes with few clicks using our KPA
            Platform
          </h3>
          <p className='card-desc'>
            Unlike traditional RPA that automates routine and mundane task with seperate IDP add-ons, we provide comprehensive business
            process automation with secure, no-code and data-driven Knowledge Process Automation (KPA) platform for businesses of all size.
          </p>
          <p className='card-desc'>
            Business users can define their process, ingest data from any source, integrate with existing system & external entities and get
            true digitally transformed business processes.
          </p>
        </div>
        <div className='inner inner-card-1'>
          <h4 className=' inner-card-title'>Human-Centric AI</h4>
          <p className='inner-card-desc'>Human controls and automate their own business process using AI Augmentation.</p>
        </div>
        <div className='inner inner-card-2'>
          <h4 className='inner-card-title'>Easy Process Onboarding</h4>
          <p className='inner-card-desc'>Business users can truly automate their business process without any expertise.</p>
        </div>
        <div className='inner inner-card-3'>
          <h4 className='inner-card-title'>Arabic Text Recognition and Analytic</h4>
          <p className='inner-card-desc'>
            Detection and Recognition of English & Arabic text from any data source be it structured, semi-structured and unstructured data.
          </p>
        </div>
        <div className='inner inner-card-4'>
          <h4 className='inner-card-title'>Process Intelligence</h4>
          <p className='inner-card-desc'>Harness data collected from each steps of business process using intelligent workflows.</p>
        </div>
        <div className='inner inner-card-5'>
          <h4 className='inner-card-title'>Cloud-agnostic and On-premise deployment</h4>
          <p className='inner-card-desc'>
            Deployable on any infrastructure of your choice, no lock-in on any system with easy pluggable modules with your existing systems
          </p>
        </div>
        <div className='inner inner-card-6'></div>
      </div>
    </section>
  );
};

export default ValueWeDeliver;
