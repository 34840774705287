import React, { FC } from "react";
import "./UpgradePage.scss";
import "../../components/Header/Header.scss";
import AppLogo from "../../assets/logo/alazka-logo.svg";
import { Link } from "react-router-dom";

const UpgradePage: FC = () => {
  return (
    <div className='get-started-page'>
      <section className={`main-header headerFixed" not-header-fixed`}>
        <Link to='/'>
          <div className='app-logo'>
            <img src={AppLogo} width='100%' alt='Alazka.ai' />
          </div>
        </Link>
      </section>
      <div className='page-title'>Choose the right Alazka AI for you</div>
      <div className='options-container'>
        <div className='pro'>
          <div>
            <div className='plan-title'>PROFESSIONAL</div>
            <div className='plan-price'>
              $0.06<sup>/page</sup>
            </div>
            <div className='plan-desc'>
              For business teams seeking to add advanced tools and improve workflows, capture data points from documents and train on their
              data models
            </div>
          </div>
          <div className='getstarted-btn'>
            <a href='#'>
              <button>Starts at $299/Model Month</button>
            </a>
          </div>
        </div>
        <div className='enterprice'>
          <div>
            <div className='plan-title'>ENTERPRISE</div>
            <div className='plan-desc'>
              Suitable for organizations that deal with multiple document types, implement custom workflows, train on their data
            </div>
          </div>
          <div className='getstarted-btn'>
            <a href='#'>
              <button>Contact Sales</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradePage;
