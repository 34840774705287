import React, { FC } from "react";
import HighlightIcon1 from "../../assets/icons/highlights/1.svg";
import HighlightIcon2 from "../../assets/icons/highlights/2.svg";
import HighlightIcon3 from "../../assets/icons/highlights/3.svg";
import HighlightIcon4 from "../../assets/icons/highlights/4.svg";
import HighlightIcon5 from "../../assets/icons/highlights/5.svg";
import HighlightIcon6 from "../../assets/icons/highlights/6.svg";
import HighlightIcon7 from "../../assets/icons/highlights/7.svg";
import HighlightIcon8 from "../../assets/icons/highlights/8.svg";
import "./Highlights.scss";

const Highlights: FC = () => {
  return (
    <section className='highlights' id='highlights'>
      <div className='section-title'>Highlights</div>
      <div className='highlights-cards'>
        <div className='row row-1'>
          <div className='card card-1'>
            <img src={HighlightIcon1} style={{ width: "100px" }} alt='' />
            <div className='text'>
              Document Input Types: JPEG (*.jpg), PDF (*.pdf), <br />
              PNG (*.PNG)
            </div>
          </div>
          <div className='card card-2'>
            <img src={HighlightIcon2} style={{ width: "60px" }} alt='' />
            <div className='text'>Page Auto Cropping</div>
          </div>
          <div className='card card-3'>
            <img src={HighlightIcon3} alt='' />
            <div className='text'>Output Formats: Text, JSON, CSV, DOC</div>
          </div>
        </div>
        <div className='row row-2'>
          <div className='card card-4'>
            <img src={HighlightIcon4} alt='' />
            <div className='text'>Language Detection</div>
          </div>
          <div className='card card-5'>
            <img src={HighlightIcon5} alt='' />
            <div className='text'>Page Auto Rotation</div>
          </div>
          <div className='card card-6'>
            <img src={HighlightIcon6} alt='' />
            <div className='text'>No Hidden Charges</div>
          </div>
        </div>
        <div className='row row-3'>
          <div className='card card-7'>
            <img src={HighlightIcon7} alt='' />
            <div className='text'>Resolution Adjustment</div>
          </div>
          <div className='card card-8'>
            <img src={HighlightIcon8} alt='' />
            <div className='text'>Usage based pricing model</div>
          </div>
        </div>
      </div>
      <div className='highlights-dark-bg'></div>
    </section>
  );
};

export default Highlights;
