import React, { FC } from "react";
import "./UseCases.scss";
import UseCaseImg1 from "../../assets/imgs/user-cases/1.svg";
import UseCaseImg2 from "../../assets/imgs/user-cases/2.svg";
import UseCaseImg3 from "../../assets/imgs/user-cases/3.svg";
import UseCaseImg4 from "../../assets/imgs/user-cases/4.svg";
import UseCaseImg5 from "../../assets/imgs/user-cases/5.svg";

const UseCases: FC = () => {
  return (
    <section className='use-cases' id='use-cases'>
      <h2 className='section-title'>Use Cases</h2>

      <div className='use-cases-list'>
        <div className='row row-1'>
          <div className='left-content'>
            <div className='title'>Learn how our KPA platform will help onboard various business-critical use cases</div>
            <div className='desc'>
              Our Knowledge Process Automation (KPA) platform offers 100% analysis and mining of unstructured data which is out of scope for
              many process automation solution like auto generating of Good Received Note (GRN), responding to customer with quick
              information in real-time, three-way matching with PO and Invoice for Accounts Payable.
            </div>
          </div>
          <div className='right-content'>
            <div className='content-img'>
              <img src={UseCaseImg1} alt='User Cases' />
            </div>
          </div>
        </div>
        <div className='row row-2'>
          <div className='left-content'>
            <div className='title'>Warehouse Automation</div>
            <div className='desc'>
              Warehouse automation can automate inventory movement within and outside warehouses to customers with minimal human
              interference.
            </div>
          </div>
          <div className='right-content'>
            <div className='content-img'>
              <img src={UseCaseImg2} alt='' />
            </div>
          </div>
        </div>
        <div className='row row-3'>
          <div className='left-content'>
            <div className='title'>Procurement</div>
            <div className='desc'>
              We ensure procurement departments process their documents like Purchase Orders, Service Agreements, Contracts, Payment
              Receipts with easy and automated workflows.
            </div>
          </div>
          <div className='right-content'>
            <div className='content-img'>
              <img src={UseCaseImg3} alt='' />
            </div>
          </div>
        </div>
        <div className='row row-4'>
          <div className='left-content'>
            <div className='title'>KYC</div>
            <div className='desc'>
              KYC is a critical process in the customer and vendor onboarding. Unstructured documents involved makes it lengthy with high
              onboarding costs and low conversion rates.
            </div>
            <div className='desc'>
              Alazka AI reads and understands all the documents & identity to save time and money on false positives and verify the identity
              of your customers in as little few seconds.
            </div>
          </div>
          <div className='right-content'>
            <div className='content-img'>
              <img src={UseCaseImg4} alt='' />
            </div>
          </div>
        </div>
        <div className='row row-5'>
          <div className='left-content'>
            <div className='title'>Financial Services</div>
            <div className='desc'>
              Improved and Efficient Accounts Payable & Receivable functions using 100% invoice process automation.
            </div>
            <div className='desc'>
              Alazka AI is a powerful platform that learns quickly and adjusts its predictions of the invoices, based on pre-training from
              millions of invoices.
            </div>
          </div>
          <div className='right-content'>
            <div className='content-img'>
              <img src={UseCaseImg5} alt='' />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UseCases;
