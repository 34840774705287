import { Drawer } from "antd";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import AppLogo from "../../assets/logo/alazka-logo.svg";

import "./Header.scss";

function scrollToTargetAdjusted(id: string) {
  var element = document.getElementById(id);
  var headerOffset = 120;
  var elementPosition: any = element?.getBoundingClientRect().top;
  var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
}

const Header: FC = () => {
  const [fixedHeader, setFixedHeader] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 130) {
      setFixedHeader(true);
    } else if (scrolled <= 130) {
      setFixedHeader(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);
  return (
    <section className={`main-header ${fixedHeader ? "headerFixed" : "not-header-fixed"}`}>
      <div className='app-logo'>
        <div className='app-logo' onClick={() => scrollToTargetAdjusted("alazka")}>
          <img src={AppLogo} width='100%' alt='Alazka.ai' />
        </div>
      </div>

      <div className='nav-menu'>
        <div className='nav-menu-item' onClick={() => scrollToTargetAdjusted("value-we-devilver")}>
          Features
        </div>
        <div className='nav-menu-item' onClick={() => scrollToTargetAdjusted("use-cases")}>
          Use Cases
        </div>
        <div className='nav-menu-item' onClick={() => scrollToTargetAdjusted("integrations")}>
          Integrations
        </div>
        <div className='nav-menu-item' onClick={() => scrollToTargetAdjusted("pricing")}>
          Pricing
        </div>
      </div>
      <div className='right-action-btn'>
        <a className='signin-btn' href='https://kapp.alazka.ai/account/sign-in'>
          <button>Sign in</button>
        </a>
        <Link to='/get-started' className='get-started-btn'>
          <button>Get Started</button>
        </Link>
      </div>

      <div className='hamburger-menu' onClick={() => setOpen(true)}>
        <div className='line'></div>
        <div className='line'></div>
        <div className='line'></div>
      </div>

      <Drawer title={null} className='nav-mobile-drawer' placement='right' width={250} onClose={onClose} open={open}>
        <div className='nav-menu'>
          <div
            className='nav-menu-item'
            onClick={() => {
              scrollToTargetAdjusted("value-we-devilver");
              setOpen(false);
            }}>
            Features
          </div>
          <div
            className='nav-menu-item'
            onClick={() => {
              scrollToTargetAdjusted("use-cases");
              setOpen(false);
            }}>
            Use Cases
          </div>
          <div
            className='nav-menu-item'
            onClick={() => {
              scrollToTargetAdjusted("integrations");
              setOpen(false);
            }}>
            Integrations
          </div>
          <div
            className='nav-menu-item'
            onClick={() => {
              setOpen(false);
              scrollToTargetAdjusted("pricing");
            }}>
            Pricing
          </div>
        </div>
        <div className='right-action-btn'>
          <a className='signin-btn' href='https://kapp.alazka.ai/account/sign-in'>
            <button>Sign in</button>
          </a>
          <Link to='/get-started' className='get-started-btn'>
            <button>Get Started</button>
          </Link>
        </div>
      </Drawer>
    </section>
  );
};

export default Header;
