import React, { FC } from "react";
import "./Footer.scss";
import AppLogo from "../../assets/logo/alazka-logo-white.svg";
import { Button, Input, message, Modal, Space } from "antd";
import config from "../config/config";
import emailjs from "@emailjs/browser";

const Footer: FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [queryText, setQueryText] = React.useState<string>("");
  const onContactUs = () => {
    if (!name && !email && !queryText) return message.error("Please fill the required fields");
    setLoading(true);
    emailjs
      .send(
        "service_n4ud769",
        "template_uktg5al",
        {
          email: email,
          from_name: name,
          message: queryText,
        },
        "0SQCSUUuJs5L5p-6B"
      )
      .then(
        (result) => {
          setLoading(false);
          setName("");
          setEmail("");
          setQueryText("");
          Modal.success({
            title: "Thank you",
            content: "We will contact you soon ",
          });
        },
        (error) => {
          message.error("Something went wrong, Please try again later");
          setLoading(false);
        }
      );
  };
  return (
    <section className='footer-wrapper'>
      <div className='curve-shape'>
        <div className='app-logo'>
          <img src={AppLogo} alt='alazka.ai' />
        </div>
        <div className='footer-title'>
          Do you have business-critical, complex process that runs from unstructured data and needs to be automated?
        </div>
        <a href={config.hubspot}>
          <button className='request-demo'>Request a Demo</button>
        </a>
        <div className='request-tag-line'>Lets discuss knowledge-led automated solution that exceeds your expectations.</div>

        <div className='input-fields'>
          <Input type='text' value={name} placeholder='Name' onChange={(e) => setName(e.target.value)} />
          <Input type='email' value={email} placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
          <Input type='text' value={queryText} placeholder='How we can help?' onChange={(e) => setQueryText(e.target.value)} />
        </div>

        <Button loading={loading} className='contact-us-btn' onClick={onContactUs}>
          Contact Us
        </Button>
      </div>
    </section>
  );
};

export default Footer;
